@import 'globals';
@import "markup";
@import "layout";
@import "responsive";
@import "navigation";
@import "print";

#wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
}

#header {
    margin: 0 !important;

    #logo {
        padding: 15px;

        img {
            height: 140px;
        }
    }
}

#container {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    background: url("../images/herki-background.jpg") center center no-repeat;
    background-size: cover;
}

#main {
    max-width: 750px;
    margin: 0 auto;
    color: #fff;
    font-weight: 300;
    text-align: center;

    .ce_headline {
        display: inline-block;
        font-weight: $fontweightBold;
        font-size: 30px;
        line-height: 46px;
    }

    .ce_headline, .ce_text {
        margin-bottom: 16px;
        padding: 2px 20px;
        background: rgba(0,0,0,.3);
    }

    .ce_text {
        font-size: 16px;
    }

    h1, p {
        margin: 0;
    }

    a {
        color: #0061a7;
        text-decoration: underline;
    }

    .rs-columns {
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }

    .rs-column {
        width: 26%;
        padding: 0 10px;
    }
}

#footer {
    padding-top: 0;
    padding-bottom: 0;

    ul, li {
        margin: 0;
        padding: 0;
        list-style-type: none;
        line-height: 30px;
        font-size: 12px;
    }

    a {
        color: #fff;
        text-decoration: underline;
    }
}
